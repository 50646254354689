/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  function makeElementClickable() {
    $(".row--blog>article,.row--blog-featured>article,.featured-items__item>div,.project").click(function() {
      if( ! $(this).find("a").hasClass("nolink") ) {
        if( $(this).find("a.btn--more").attr("target") == "_blank" ) {
          window.open($(this).find("a.btn--more").attr("href"));
        } else {
          window.location=$(this).find("a.btn--more").attr("href");
          //return false;
        }
      }
    });

    $('.featured-items__item').each(function() {
      if ($(this).find('a.nolink').length > 0) { $(this).addClass('item-nolink') };
    });
  }

  function initHomepageSlider() {
    $('.banner--slider').slick({
      dots: true,
      slide: '.slide',
      fade: true,
      cssEase: 'linear',
      adaptiveHeight: false,
      autoplay: true,
      autoplaySpeed: 2500,
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            adaptiveHeight: true,
            dots: false
          }
        }
      ]
    });
  }

  function initEventsSlider() {
    $('.events-slider').slick({
      slide: '.events-slider__slide',
      //fade: true,
      speed: 0,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            speed: 200,
            dots: true
          }
        }
      ]
    });
  }

  function RemoveReadsDuplicates() {
    var seen = {};
    $('.reads-list>div ul li').each(function() {
        var txt = $(this).text();
        if (seen[txt])
            $(this).remove();
        else
            seen[txt] = true;
    });
  }

  function reverseEvents() {
    // var list = $('.events-slider__wrapper');
    // var listItems = list.children('.row');
    // list.append(listItems.get().reverse());

    $(".single-event").sort(function(a,b){
        return new Date($(a).attr("data-date")) > new Date($(b).attr("data-date"));
    }).each(function(){
        $(".events-slider__wrapper").prepend(this);
    });
  }

  // function imageSrcToWhite() {
  //   $('.nav-link').each(function(e){
  //   var src = $(this).find('img').attr('src');
  //   $(this).hover(function(){
  //     //console.log('hi');
  //     //console.log(src);
  //       $(this).find('img').attr('src', src.replace('.png', '_white.png'));
  //     }, function(){
  //       $(this).find('img').attr('src', src);
  //     });
  //   });
  // }

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        makeElementClickable();
        $('[data-toggle="tooltip"]').tooltip()
        // imageSrcToWhite();
        $('.navbar-toggler').addClass('collapsed');

        $('.navbar-toggler').click( function() {
          if( $('.body__wrapper').hasClass('hidecontent') ) {
            $('.body__wrapper').removeClass('hidecontent');
          } else {
            $('.body__wrapper').addClass('hidecontent');
          }
        });
        RemoveReadsDuplicates();
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        initHomepageSlider();
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'news_events': {
      init: function() {
        // JavaScript to be fired on the about us page
        initEventsSlider();
        reverseEvents();
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
